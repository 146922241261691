import React from "react";
import "./CTA.css";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { gtagSendEvent } from "../../utils/gtaghelper";

const CTA = () => {
  return (
    <motion.div
      initial={{
        x: "0%",
        y: -100,
        opacity: 0,
      }}
      whileInView={{
        x: "0%",
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.6,
      }}
      viewport={{ once: true }}
      className="section-padding"
    >
      <div className="row cta">
        <div className="col-lg-8 col-12">
          <h3>Let’s make things happen</h3>

          <p className="pt-2 pb-4">
            Contact us today to learn more about how our digital marketing
            services can help your business grow and succeed online.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
           href="tel:+91-6363053425"
            onClick={(e) => {
                e.preventDefault();
                gtagSendEvent("conversion_event_contact", "tel:+91-6363053425");
              }}
          >
            <button className="btn-positivus">Get your free proposal</button>
          </a>
        </div>
        <div className="col-lg-4 d-lg-flex d-none">
          <img src={images.thingshappen} alt="thingshappen" />
        </div>
      </div>
    </motion.div>
  );
};

export default CTA;
